import { useState, useEffect } from 'react';
import './App.css';
import SearchIcon from './search.svg';
import MovieCard from './MovieCard';
const API_KEY = "1a6dc93c";
const API_URL = `http://omdbapi.com?apikey=${API_KEY}`;

const App = () => {
    const [ movies, setMovies ] = useState([]);
    const [searchTerm, setSearchTerm ] = useState('');
    const searchMovies = async (title) => {
        const response = await fetch(`${API_URL}&s=${title}
        `);
        const data = await response.json();
 
        setMovies(data.Search);
    }
    const CheckSearchTerm = () => {
        let message = "";
        if(searchTerm == '') {
            message = (
                <div>
                   <h2> Please Search a Movie. </h2>
                </div>
            );
        }
        else {
            message = (
                <div>
                    <h2>No movies found</h2>
                </div>
            )
        }
        return message;
    }
    useEffect(() => {
        searchMovies('');
    }, []);

  return (
    <div className='app'>
        <h1>MovieLand</h1>

        <div className='search'>
            <input 
                placeholder='Search for movies'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                />
                <img 
                    src={SearchIcon}
                    alt='Search Icon'
                    onClick={() => searchMovies(searchTerm)}
                />
        </div>
        <div className='container'>
            {

                movies?.length > 0 
                ? 
                ( movies.map((movie) => (
                        <MovieCard movie={movie} />
                    ))  
                )
                : 
                (<div className='empty'><CheckSearchTerm /></div>)
            }
        </div>
    </div>
  )
}

export default App